import { Image, Link, Flex, Skeleton } from '@chakra-ui/react';
import React, { useState, useCallback, useEffect } from 'react';

import type { Transaction } from 'types/api/transaction';

import useNftMetadata from 'ui/token/useNftMetadata';
import type { AdditionalDataType } from 'ui/txs/TxsWithFrontendSorting';

type Props = {
  tx: Transaction & { additionalInfo?: AdditionalDataType };
  isLoading?: boolean;
};

const LatestTxsItemImage = ({ tx, isLoading }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const nftMetadataQuery = useNftMetadata(
    tx?.additionalInfo?.token_contract_address_hash,
    tx?.additionalInfo?.token_id as string | undefined,
  );

  const handleImageLoad = useCallback(() => setImageLoaded(true), []);

  const imageUrl = nftMetadataQuery.data?.image;

  useEffect(() => {
    if (!imageUrl) {
      return;
    }
    const img = new window.Image();
    img.src = imageUrl;
    if (img.complete) {
      setImageLoaded(true);
    } else {
      img.onload = () => setImageLoaded(true);
      img.onerror = () => setImageLoaded(true);
    }
  }, [imageUrl]);

  if (!tx?.additionalInfo?.token_contract_address_hash || !tx?.additionalInfo?.token_id) {
    return null;
  }

  return (
    <Skeleton isLoaded={!isLoading && imageLoaded} display="inline-block">
      <Flex alignItems="center" justifyContent="center">
        <Link
          target="_blank"
          href={`/token/0x${tx.additionalInfo?.token_contract_address_hash}/instance/${tx.additionalInfo?.token_id}`}
        >
          <Image
            border="1px solid"
            borderColor="gray.500"
            src={imageUrl}
            alt="NFT item image"
            w="90px"
            onLoad={handleImageLoad}
            // eslint-disable-next-line react/jsx-no-bind
            onError={(e) => {
              setImageLoaded(true);
              e.currentTarget.src = '/static/no-image.svg';
            }}
          />
        </Link>
      </Flex>
    </Skeleton>
  );
};

export default React.memo(LatestTxsItemImage);
