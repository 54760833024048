export const TX_METHODS = {
  DECODE_NFT_CUBE: 'decodeNFTCubeForNFTItemWithVL',
  DECODE_CUBE: 'decodeCubeForNFTItemWithVL',
  DECODE: 'decode',
  SEND_TO_ADDRESS: 'sendToAddress',
  SET_APPROVAL_FOR_ALL: 'setApprovalForAll',
  CREATE_ITEM_FROM_PARTS: 'createItemFromParts',
  CREATE_ATOMIC_SALE: 'createAtomicSale',
  MINT: 'mint',
  ACTIVATE: 'activate',
} as const;

export type TxMethod = (typeof TX_METHODS)[keyof typeof TX_METHODS];
