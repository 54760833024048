import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import type { Transaction } from 'types/api/transaction';

import { useAdditionalDataQuery } from 'lib/api/fetchAdditionalTransactionData';
import useApiQuery from 'lib/api/useApiQuery';
import { AddressHighlightProvider } from 'lib/contexts/addressHighlight';
import useIsMobile from 'lib/hooks/useIsMobile';
import { TX } from 'stubs/tx';
import GradientButton from 'ui/gradientButton/gradientButton';
import type { AdditionalDataType } from 'ui/txs/TxsWithFrontendSorting';

import LatestTxsItem from './LatestTxsItem';
import LatestTxsItemMobile from './LatestTxsItemMobile';

const LatestTransactions = () => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const txsCount = isMobile ? 5 : 15;
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_txs', {
    queryOptions: {
      placeholderData: Array(txsCount).fill(TX),
    },
  });

  const { data: additionalData } = useAdditionalDataQuery(data?.map(tx => tx.hash) || []);

  const itemsWithAdditionalData = data?.map((transaction) => {
    const additionalInfo = additionalData?.find(item => `0x${item.transaction_hash}` === transaction.hash) || null;
    return {
      ...transaction,
      additionalInfo,
    };
  });

  const viewAllTransactionsHandle = useCallback(() => {
    router.push('/txs');
  }, [router]);

  if (isError) {
    return <Text mt={4}>No data. Please reload the page.</Text>;
  }

  if (itemsWithAdditionalData) {
    return (
      <>
        <Flex justify="space-between" align="center" mt={7}>
          <Heading
            as="h4"
            size="sm"
            mb={4}
            mt={7}
            color="white"
            position="relative"
            zIndex={2}
          >
            Latest transactions
          </Heading>
          <GradientButton caption="View all transactions" action={viewAllTransactionsHandle} />
        </Flex>
        <Box mb={3} display={{ base: 'block', lg: 'none' }}>
          {itemsWithAdditionalData?.slice(0, txsCount).map((tx, index) => (
            <LatestTxsItemMobile
              key={tx.hash + (isPlaceholderData ? index : '')}
              tx={tx}
              isLoading={isPlaceholderData}
            />
          ))}
        </Box>

        <AddressHighlightProvider>
          <Box mb={3} display={{ base: 'none', lg: 'block' }}>
            {itemsWithAdditionalData?.slice(0, txsCount).map((tx, index) => (
              <LatestTxsItem
                key={tx.hash + (isPlaceholderData ? index : '')}
                tx={tx as Transaction & { additionalInfo?: AdditionalDataType }}
                isLoading={isPlaceholderData}
              />
            ))}
          </Box>
        </AddressHighlightProvider>
        <Flex justify="flex-end" align="center">
          <GradientButton
          mt="10px" caption="View all transactions" action={viewAllTransactionsHandle} />
        </Flex>
      </>
    );
  }

  return null;
};

export default LatestTransactions;
