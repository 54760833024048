/* spell-checker: disable */

import type { GunzNftMetadata } from 'types/backchainApi/nftMetadata';
import type { ArrayElement } from 'types/utils';

export const BACKCHAIN_RESOURCES = {
  nft_metadata: {
    path: '/api/v1/:scAddress/:tokenId',
    pathParams: ['scAddress' as const, 'tokenId' as const],
  },
};

export type BackchainResourceName = keyof typeof BACKCHAIN_RESOURCES;

export type BackchainResourcePayload<Q extends BackchainResourceName> =
  ResourcePayloadA<Q>;

export type ResourcePayloadA<Q extends BackchainResourceName> =
  Q extends 'nft_metadata' ? GunzNftMetadata : never;

type BackchainResourcePathMap = {
  [K in BackchainResourceName]: (typeof BACKCHAIN_RESOURCES)[K]['path'];
};

export type BackchainResourcePath =
  BackchainResourcePathMap[keyof BackchainResourcePathMap];

type BackchainResourcePathParamName<Resource extends BackchainResourceName> =
  (typeof BACKCHAIN_RESOURCES)[Resource] extends { pathParams: Array<string> }
    ? ArrayElement<(typeof BACKCHAIN_RESOURCES)[Resource]['pathParams']>
    : string;

export type BackchainResourcePathParams<
  Resource extends BackchainResourceName,
> = (typeof BACKCHAIN_RESOURCES)[Resource] extends { pathParams: Array<string> }
  ? Record<BackchainResourcePathParamName<Resource>, string | undefined>
  : never;

export interface BackchainApiResource {
  path: BackchainResourcePath;
  endpoint?: string;
  basePath?: string;
  pathParams?: Array<string>;
  headers?: RequestInit['headers'];
}
