import { compile } from 'path-to-regexp';

import config from 'configs/app';

import { BACKCHAIN_RESOURCES } from './resources';
import type {
  BackchainApiResource,
  BackchainResourceName,
  BackchainResourcePathParams,
} from './resources';

export default function buildBackchainUrl<R extends BackchainResourceName>(
  resourceName: R,
  pathParams?: BackchainResourcePathParams<R>,
  queryParams?: Record<
    string,
    string | Array<string> | number | boolean | null | undefined
  >,
): string {
  const resource: BackchainApiResource = BACKCHAIN_RESOURCES[resourceName];
  const baseUrl = config.api.backchainEndpoint;
  const url = new URL(compile(resource.path)(pathParams), baseUrl);

  queryParams &&
    Object.entries(queryParams).forEach(([key, value]) => {
      // there are some pagination params that can be null or false for the next page
      value !== undefined &&
        value !== '' &&
        url.searchParams.append(key, String(value));
    });

  return url.toString();
}
