import type { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import type { GunzNftMetadata } from 'types/backchainApi/nftMetadata';

import useBackchainApiQuery from 'lib/api/backchainApi/useBackchainApiQuery';
import type { ResourceError } from 'lib/api/resources';

export type NftMetadataQuery = UseQueryResult<GunzNftMetadata, ResourceError>;

export default function useNftMetadata(
  scAddress?: string,
  tokenId?: string,
  enabled = true,
): NftMetadataQuery {
  const queryResult = useBackchainApiQuery<'nft_metadata'>('nft_metadata', {
    queryOptions: {
      enabled: enabled && Boolean(scAddress && tokenId),
    },
    pathParams: {
      scAddress,
      tokenId,
    },
  });

  return React.useMemo(
    () => ({
      ...queryResult,
    }),
    [queryResult],
  );
}
