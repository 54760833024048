import _omit from 'lodash/omit';
import _pickBy from 'lodash/pickBy';
import React from 'react';

import useFetch from 'lib/hooks/useFetch';
import type { Params as FetchParams } from 'lib/hooks/useFetch';

import buildBackchainUrl from './buildBackchainUrl';
import { BACKCHAIN_RESOURCES } from './resources';
import type {
  BackchainResourceName,
  BackchainApiResource,
  BackchainResourcePathParams,
} from './resources';

export interface Params<R extends BackchainResourceName> {
  pathParams?: BackchainResourcePathParams<R>;
  queryParams?: Record<
    string,
    string | Array<string> | number | boolean | undefined | null
  >;
  fetchParams?: Pick<FetchParams, 'body' | 'method' | 'signal' | 'headers'>;
}

export default function useBackchainApiFetch() {
  const fetch = useFetch();

  return React.useCallback(
    <
      R extends BackchainResourceName,
      SuccessType = unknown,
      ErrorType = unknown,
    >(
      resourceName: R,
      { pathParams, queryParams, fetchParams }: Params<R> = {},
    ) => {
      const resource: BackchainApiResource = BACKCHAIN_RESOURCES[resourceName];
      const url = buildBackchainUrl(resourceName, pathParams, queryParams);

      const headers = _pickBy(
        {
          ...resource.headers,
          ...fetchParams?.headers,
        },
        Boolean,
      ) as HeadersInit;

      return fetch<SuccessType, ErrorType>(
        url,
        {
          credentials: 'same-origin',
          headers,
          ..._omit(fetchParams, 'headers'),
        },
        {
          resource: resource.path,
          omitSentryErrorLog: true,
        },
      );
    },
    [fetch],
  );
}
