import { useQuery } from '@tanstack/react-query';

import type { StaticRoute } from 'nextjs-routes';
import { route } from 'nextjs-routes';

import useFetch from 'lib/hooks/useFetch';
import type { AdditionalDataType } from 'ui/txs/TxsWithFrontendSorting';

const fetchAdditionalTransactionData = async (fetch: ReturnType<typeof useFetch>, TxIds: string[]): Promise<AdditionalDataType[]> => {
  const url = route({ pathname: '/node-api/transactions-detail' as StaticRoute<'/api/transactions-detail'>['pathname'] });
  const urlWithParams = `${url}?transaction_hashes=${TxIds.join(',')}`;

  const result = await fetch<AdditionalDataType[], unknown>(urlWithParams, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!Array.isArray(result)) {
    throw result;
  }
  return result;
};

export const useAdditionalDataQuery = (transactions: string[]) => {
  const fetch = useFetch();
  return useQuery<AdditionalDataType[] | null, Error>({
    queryKey: ['additionalData', transactions, fetch],
    queryFn: async () => {
      if (!transactions) {
        return null;
      }
      return fetchAdditionalTransactionData(fetch, transactions);
    },
    enabled: Boolean(transactions.length > 0),
  });
};
