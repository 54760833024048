import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { Params as FetchParams } from 'lib/hooks/useFetch';

import type { ResourceError } from '../resources';
import type {
  BackchainResourceName,
  BackchainResourcePayload,
  BackchainResourcePathParams,
} from './resources';
import useBackchainApiFetch from './useBackchainApiFetch';

export interface Params<
  R extends BackchainResourceName,
  E = unknown,
  D = BackchainResourcePayload<R>,
> {
  pathParams?: BackchainResourcePathParams<R>;
  queryParams?: Record<
    string,
    string | Array<string> | number | boolean | undefined
  >;
  fetchParams?: Pick<FetchParams, 'body' | 'method' | 'headers'>;
  queryOptions?: Partial<
    Omit<
      UseQueryOptions<BackchainResourcePayload<R>, ResourceError<E>, D>,
      'queryFn'
    >
  >;
}

export function getBackchainResourceKey<R extends BackchainResourceName>(
  resource: R,
  { pathParams, queryParams }: Params<R> = {},
) {
  if (pathParams || queryParams) {
    return [resource, { ...pathParams, ...queryParams }];
  }

  return [resource];
}

export default function useBackchainApiQuery<
  R extends BackchainResourceName,
  E = unknown,
  D = BackchainResourcePayload<R>,
>(
  resource: R,
  { queryOptions, pathParams, queryParams, fetchParams }: Params<R, E, D> = {},
) {
  const apiFetch = useBackchainApiFetch();

  return useQuery<BackchainResourcePayload<R>, ResourceError<E>, D>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey:
      queryOptions?.queryKey ||
      getBackchainResourceKey(resource, { pathParams, queryParams }),
    queryFn: async ({ signal }) => {
      return apiFetch(resource, {
        pathParams,
        queryParams,
        fetchParams: { ...fetchParams, signal },
      }) as Promise<BackchainResourcePayload<R>>;
    },
    ...queryOptions,
  });
}
