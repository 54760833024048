import type { Transaction } from 'types/api/transaction';
import type { TxMethodUiConfig } from 'types/ui';

import { TX_METHODS } from 'lib/tx/methods';

const TREASURY_CONTRACT_NAME = 'GUNFinanceTreasury';

export function checkIsDecode(tx: Transaction | undefined): boolean {
  if (!tx || tx.method == null) {
    return false;
  }

  switch (tx.method) {
    case TX_METHODS.DECODE_NFT_CUBE:
    case TX_METHODS.DECODE_CUBE:
    case TX_METHODS.DECODE:
      return true;
    default:
      return false;
  }
}

export default function getTxMethodTypeConfig(
  tx: Transaction | undefined,
): TxMethodUiConfig | null {
  if (!tx) {
    return null;
  }

  if (tx.method != null) {
    switch (tx.method) {
      case TX_METHODS.DECODE_NFT_CUBE:
      case TX_METHODS.DECODE_CUBE:
      case TX_METHODS.DECODE:
        return {
          colorScheme: 'txTypeDecode',
          icon: 'decode',
          label: 'Decode',
        };

      case TX_METHODS.SEND_TO_ADDRESS:
        if (tx.to && tx.to.implementations) {
          if (tx.to.implementations[0]?.name === TREASURY_CONTRACT_NAME) {
            return {
              colorScheme: 'txTypeTreasury',
              icon: 'treasury',
              label: 'OTG Payment',
            };
          }
        }
        return null;

      case TX_METHODS.SET_APPROVAL_FOR_ALL:
        return {
          colorScheme: 'txTypeApprove',
          icon: 'approve',
          label: 'Approve',
        };

      case TX_METHODS.CREATE_ITEM_FROM_PARTS:
        return {
          colorScheme: 'txTypeCreateItemFromParts',
          icon: 'create_item_from_parts',
          label: 'Assemble Item',
        };

      case TX_METHODS.CREATE_ATOMIC_SALE:
        return {
          colorScheme: 'txTypeSale',
          icon: 'sale',
          label: 'NFT Trade',
        };

      case TX_METHODS.MINT:
        return {
          colorScheme: 'txTypeMint',
          icon: 'mint',
          label: 'Mint',
        };

      case TX_METHODS.ACTIVATE:
        return {
          colorScheme: 'txTypeActivate',
          icon: 'activate',
          label: 'Activate',
        };

      default:
        return null;
    }
  }

  return null;
}
